/* src/Feed.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  background-color: black;
  color: white;
  font-family: sans-serif;
}

/* --- Keyframes --- */
@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}
@keyframes slideInFromTop {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.feed-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: black;
  outline: none; /* Added to remove focus outline */
}

.feed-logo-link {
  position: absolute;
  top: 20px; /* Original top position */
  left: 20px; /* Original left position */
  z-index: 10;
  max-height: 50px; /* Original max-height */
  transition: top 0.5s ease-in-out, left 0.5s ease-in-out, transform 0.5s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  display: block;
}

.feed-logo {
  position: absolute;
  top: 20px; /* Original top position */
  left: 20px; /* Original left position */
  z-index: 10;
  max-height: 50px; /* Original max-height */
  transition: top 0.5s ease-in-out, left 0.5s ease-in-out, transform 0.5s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  display: block;
}


/* --- Top Right Icons & Profile --- */
.top-right-icons {
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 18px;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
}

.top-right-icons a, .search-icon-button, .upload-game-button, .earn-button {
  color: rgba(255, 255, 255, 0.6); /* Grey color */
  font-size: 1.2em;
  transition: color 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  text-decoration: none; /* For Upload Game text button */
  padding: 0; /* For Upload Game text button */
}

.upload-game-button, .earn-button {
    border-radius: 8px;
    padding: 8px 12px;
    width: auto;
    height: auto;
    font-size: 0.9em;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: bold;
}


.top-right-icons a:hover, .search-icon-button:hover, .search-icon-button.active, .upload-game-button:hover, .earn-button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

.search-icon.active {
    color: #ff7f00; /* Orange when active */
}


/* --- Profile Button & Dropdown Header Styles (Reused from Header.css) --- */
.profile-container-header {
  position: relative; /* For dropdown positioning */
  display: flex;
  align-items: center;
}

.profile-button-header {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center; /* Vertically center icon/image */
  justify-content: center; /* Horizontally center content */
}

.desktop-profile-pic {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  display: block; /* Ensure no extra space */
}

.profile-initials-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ff7f00; /* Example background color */
  color: white;
  font-weight: bold;
  font-size: 1em;
}

.user-dropdown-header {
  position: absolute;
  top: calc(100% + 10px); /* Position below the button */
  right: 0;
  background-color: #1a1a1a;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 100;
  width: 120px; /* Adjust width as needed */
  overflow: hidden; /* Prevent border-radius overflow */
}

.user-dropdown-header .dropdown-item {
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: white;
  font-size: 0.9em;
}

.user-dropdown-header .dropdown-item:hover {
  background-color: #333;
}

.user-dropdown-header .signout-dropdown-item {
  border-top: 1px solid #444; /* Separator for sign-out */
}

.signin-button-custom {
  background-color: #ff7f00;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s ease;
}

.signin-button-custom:hover {
  background-color: #e67300;
}

/* --- Search Bar --- */
.search-bar-container {
    position: absolute;
    top: 80px; /* Shifted to top of page */
    left: 50%;
    transform: translateX(-50%);
    background-color: #1a1a1a;
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 20;
    border: 2px solid #333;
}

.search-input {
    background-color: #222;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1em;
    width: 200px; /* Adjust as needed */
}
.search-input:focus {
    outline: none;
    border-color: #ff7f00; /* Highlight on focus if desired */
    box-shadow: 0 0 5px rgba(255, 127, 0, 0.5);
}


.search-close-button, .search-results-close-button, .game-modal-close-button {
    background: none;
    border: none;
    color: #aaa;
    font-size: 1.3em;
    cursor: pointer;
    transition: color 0.2s ease;
}

.search-close-button:hover, .search-results-close-button:hover, .game-modal-close-button:hover {
    color: white;
}

/* --- Search Results --- */
.search-results-overlay {
    position: absolute; /* Changed to absolute */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black overlay */
    z-index: 21; /* Above search bar but below modals if any */
    overflow-y: auto; /* In case of many results */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-results-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 31;
}

.search-results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
    gap: 15px;
    max-width: 1200px; /* Maximum width for the grid */
    width: 95%; /* Take up most of the overlay width */
    margin-top: 40px; /* Space below close button */
}

.search-result-card {
    background-color: #1a1a1a;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.search-result-card:hover {
    transform: scale(1.05);
}

.search-result-thumbnail {
    width: 100%;
    height: 100px; /* Fixed height for thumbnails */
    object-fit: cover;
    display: block;
}

.search-result-title {
    padding: 10px;
    font-size: 0.9em;
    color: #eee;
    text-align: center;
}


/* --- Content Wrapper --- */
.feed-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feed-content-wrapper.animate-slide-up {
    animation: slideInFromBottom 0.5s ease-out forwards;
}
.feed-content-wrapper.animate-slide-down {
    animation: slideInFromTop 0.5s ease-out forwards;
}

.feed-thumbnail,
.feed-video,
.feed-iframe,
.feed-placeholder,
.modal-feed-video,
.game-result-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Updated to cover */
    display: block;
    border: none;
}

.feed-video {
    object-fit: contain; /* Ensure video fits in desktop */
}


.feed-placeholder {
    background-color: #1a1a1a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #555;
}

/* --- Info Box --- */
.feed-info {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 25px 15px 25px;
  border-radius: 8px;
  max-width: 80%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
  min-height: 110px;
  box-sizing: border-box;
}

.feed-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 8px;
  color: #ff7f00;
}

.feed-description {
  font-size: 0.9em;
  margin-bottom: 10px;
  color: #ccc;
  max-height: 60px;
  overflow-y: auto;
}

.feed-username {
  font-size: 0.8em;
  color: #aaa;
  margin-bottom: 15px;
}
.feed-username a { color: #bbb; text-decoration: none; }
.feed-username a:hover { text-decoration: underline; }

/* --- Action Buttons for Video (Request Early Access and Watch Trailer) - Centered --- */
.feed-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    gap: 10px; /* Space between buttons */
    margin-top: 10px; /* Space from description */
}

.action-text-request {
  color: #ff7f00; /* Ensure orange color */
  background: none;
  border: none;
  padding: 5px 0;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
  display: inline-block;
  margin-top: 0; /* Reset margin top */
}

.action-text-request:hover {
  color: #e67300;
}

/* --- Play Icon Button (Rounded Orange) --- */
.action-icon-play {
    position: absolute;
    bottom: 12px; /* Adjusted position slightly */
    right: 15px; /* Adjusted position slightly */
    background-color: #ff7f00; /* Orange background */
    color: white; /* White icon color for contrast */
    border: none;
    border-radius: 50%; /* Make it round */
    width: 40px; /* Fixed width */
    height: 40px; /* Fixed height */
    display: flex; /* Center icon inside */
    justify-content: center;
    align-items: center;
    font-size: 1em; /* Adjust icon size relative to button size */
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    padding: 0; /* Remove padding if fixed size */
}

.action-icon-play svg { /* Target the FontAwesome icon specifically */
    display: block; /* Ensure it behaves predictably */
}

.action-icon-play:hover {
    background-color: #e67300; /* Darker orange on hover */
    transform: scale(1.1);
}

/* --- Navigation Arrows (Smaller) --- */
.feed-nav-arrow {
  position: absolute;
  right: 15px; /* Adjusted position */
  z-index: 10;
  cursor: pointer;
  font-size: 1.4em; /* Reduced size by ~30% from 2em */
  color: rgba(255, 255, 255, 0.6); /* Slightly more subtle */
  background-color: rgba(0, 0, 0, 0.4); /* Slightly more subtle */
  padding: 4px; /* Adjusted padding */
  border-radius: 50%;
  user-select: none;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, color 0.2s ease, background-color 0.2s ease, visibility 0s linear 0.5s; /* Delay visibility change */
}
.feed-nav-arrow:hover {
   color: rgba(255, 255, 255, 1);
   background-color: rgba(0, 0, 0, 0.7);
}
.arrow-up { top: 40%; transform: translateY(-50%); }
.arrow-down { bottom: 40%; transform: translateY(50%); }
.feed-container[data-single-item="true"] .feed-nav-arrow { display: none; }


/* --- Share Button (Smaller) --- */
.feed-share-button {
  position: absolute;
  bottom: 65px; /* Adjusted position - moved up */
  right: 15px; /* Adjusted position */
  z-index: 10;
  cursor: pointer;
  font-size: 1.2em; /* Reduced size */
  color: rgba(255, 255, 255, 0.6); /* Slightly more subtle */
  background-color: rgba(0, 0, 0, 0.4); /* Slightly more subtle */
  padding: 8px; /* Adjusted padding */
  border-radius: 50%;
  opacity: 1;
  visibility: visible; /* Ensure visible initially */
  transition: opacity 0.5s ease-in-out, color 0.2s ease, background-color 0.2s ease, visibility 0s linear 0.5s; /* Delay visibility change */
}
.feed-share-button:hover {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(0, 0, 0, 0.7);
}
.feed-share-button::before { content: '🔗'; display: block; }

/* --- Action Icons Container --- */
.feed-action-icons {
    position: absolute;
    bottom: 25px;
    right: 15px; /* Adjusted position, share moved above */
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Spacing between icons */
}

.feed-action-icon {
    cursor: pointer;
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(0, 0, 0, 0.4);
    padding: 8px;
    border-radius: 50%;
    transition: color 0.2s ease, background-color 0.2s ease;
}

.feed-action-icon:hover {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 0.7);
}
.favorite-icon.favorited {
    color: #ffc107; /* Yellow for favorited star */
}
.love-icon.loved {
    color: red; /* Red for loved heart */
}
.love-favorite-icons {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 0px; /* Adjust gap between heart and star if needed */
}
.love-icon {
    margin-bottom: 4px; /* Adjust to overlap or position heart above star */
}


/* --- Watch Button --- */
.watch-button {
    position: relative; /* Position relative to allow bottom placement */
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: 2px solid #ff7f00;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    z-index: 2; /* Ensure it's above the thumbnail */
    margin-top: 10px; /* Space between request and watch buttons */
    display: inline-block; /* To control width if needed */
}

.watch-button:hover {
    background-color: #ff7f00;
    color: black;
    border-color: #e67300;
}


/* --- Leave Button --- */
.feed-leave-button {
  position: absolute;
  top: 20px; /* Aligned with logo */
  right: 20px;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 1px solid #ff7f00;
  padding: 8px 15px;
  font-size: 0.9em;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s; /* Delay visibility change */
}

/* --- Game Icon Button --- */
.game-icon-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.2s ease, transform 0.2s ease;
}

.game-icon-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
    transform: scale(1.1);
}
.game-icon {
    /* Icon styles if needed */
}


/* --- Custom Notification --- */
.copy-notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    background-color: black;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid white;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    font-size: 0.9em;
}

.copy-notification.visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
}

/* --- Playing State Adjustments --- */
.feed-container.is-playing .feed-logo {
  /* Logo position is now fixed, these rules are removed */
}

/* Hide elements instantly on visibility, fade out on opacity */
.feed-container.is-playing .feed-info,
.feed-container.is-playing .feed-nav-arrow,
.feed-container.is-playing .feed-share-button,
.feed-container.is-playing .feed-action-icons,
.feed-container.is-playing .top-right-icons,
.feed-container.is-playing .game-icon-button {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, visibility 0s linear 0.5s; /* Hide visibility after opacity transition */
}

/* Optional: Add transforms for hiding animation */
.feed-container.is-playing .feed-info {
  transform: translateX(-50%) translateY(30px);
}
.feed-container.is-playing .feed-nav-arrow {
    transform: translateY(30px);
}
.feed-container.is-playing .feed-share-button,
.feed-container.is-playing .feed-action-icons {
    transform: translateX(30px) translateY(30px); /* Move slightly right/down */
}

/* Make leave button appear */
.feed-container.is-playing .feed-leave-button {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0s linear 0s; /* Make visible instantly when opacity starts */
}


/* Loading/Error states */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure it takes full height to center vertically */
  color: #ff7f00; /* Text color if needed */
}

.orange-spinner {
  border: 3px solid rgba(255, 127, 0, 0.3); /* Light orange border */
  border-top: 3px solid #ff7f00; /* Solid orange border on top */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: rotate 1s linear infinite;
}


.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-card {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
}

.error-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ff7f00;
}

.error-description {
  font-size: 1em;
  color: #ccc;
}


.loading-overlay {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: #ff7f00;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.8em;
    z-index: 20;
}

/* DOS Loading Overlay Styles */
.dos-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1); /* Darker overlay for DOS loading */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 25; /* Ensure it's above other content */
}

.dos-loading-logo {
    max-height: 80px;
    margin-bottom: 20px;
}

.dos-loading-progress-bar {
    width: 60%;
    height: 8px;
    background-color: #333; /* Dark grey background for progress bar */
    border-radius: 4px;
    overflow: hidden; /* Ensure progress stays within bounds */
}

.dos-loading-progress {
    height: 100%;
    background-color: #ff7f00; /* Orange progress indicator */
    border-radius: 4px;
    transition: width 0.1s linear; /* Smooth progress animation */
}


/* Basic Mobile adjustments */
@media (max-width: 768px) {
  .feed-info {
    bottom: 80px; /* Raised bottom position */
    max-width: 90%;
    padding: 15px 20px 10px 20px;
    min-height: 80px; /* Adjusted min-height */
  }
  .feed-title { font-size: 1.2em; }
  .feed-description { font-size: 0.8em; max-height: 45px; }
  .action-text-request { font-size: 0.9em; }
  .action-icon-play {
      width: 36px;
      height: 36px;
      font-size: 0.9em;
      bottom: 10px;
      right: 12px;
  }

  .feed-nav-arrow {
      font-size: 1.8em; /* Further reduced */
      right: 10px;
      padding: 3px;
  }
  .feed-share-button {
      font-size: 1.1em; /* Further reduced */
      padding: 7px;
      right: 10px;
      bottom: 60px; /* Adjusted */
  }
  .feed-action-icons {
      right: 10px; /* Adjusted for mobile */
      bottom: 20px;
      gap: 8px;
  }
  .feed-action-icon {
      font-size: 1.1em;
      padding: 6px;
  }


  .watch-button {
      font-size: 1em;
      padding: 8px 16px;
      top: auto; /* Reset top */
      margin-top: 10px; /* Keep margin for spacing */
      margin-bottom: 0; /* Reset bottom margin */
      position: relative; /* Ensure relative positioning for mobile layout */
  }

  .feed-leave-button { top: 10px; right: 10px; padding: 6px 12px; font-size: 0.8em; } /* Adjusted top */
  .top-right-icons { right: 15px; top: 22px; gap: 15px; }
  .top-right-icons a, .search-icon-button { font-size: 1.1em; width: 30px; height: 30px; }

  .feed-container.is-playing .feed-logo { top: 10px; left: 15px; max-height: 40px;}

  .error-card {
      padding: 20px;
  }
  .error-title {
      font-size: 1.5em;
      margin-bottom: 10px;
  }
  .error-description {
      font-size: 0.9em;
  }

  .search-bar-container {
      width: 90%;
      flex-direction: column;
      padding: 15px;
      border-radius: 8px;
      gap: 10px;
      top: 70px; /* Adjusted for mobile */
  }
  .search-input {
      width: 100%;
      box-sizing: border-box;
  }

    .watch-button {
        top: auto; /* Raised watch button for mobile */
    }

    .game-icon-button {
        bottom: 10px;
        left: 10px;
    }
}

/* src/Feed.css - Additions for Auth Modal */

/* Auth Modal Overlay */
.auth-modal-overlay,
.report-modal-overlay,
.video-modal-overlay,
.pro-modal-overlay,
.compute-modal-overlay,
.auth-modal-overlay,
.game-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top */
}

/* Game Modal Content */
.game-modal-content {
    background-color: #1a1a1a;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 700px;
    max-height: 90%;
    overflow-y: auto; /* Scroll if content overflows */
    position: relative;
    color: white;
}

.game-modal-title {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ff7f00;
}

.game-results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
    gap: 15px;
    max-width: 1200px; /* Maximum width for the grid */
    width: 95%; /* Take up most of the overlay width */
    margin-top: 20px; /* Space below title */
}

.game-result-card {
    background-color: #222;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.game-result-card:hover {
    transform: scale(1.05);
}

.game-result-thumbnail {
    width: 100%;
    height: 100px; /* Fixed height for thumbnails */
    object-fit: cover;
    display: block;
}

.game-result-title {
    padding: 10px;
    font-size: 0.9em;
    color: #eee;
    text-align: center;
}


/* Auth Modal Content */
.auth-modal-content,
.report-modal-content,
.video-modal-content,
.pro-modal-content,
.compute-modal-content {
  background-color: #1a1a1a; /* Dark background for content area */
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
  color: white;
  position: relative; /* For close button positioning */
}

.auth-modal-title,
.report-modal-title,
.video-modal-title,
.pro-modal-title,
.compute-modal-title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff7f00; /* Orange title */
}

.auth-modal-checklist {
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
}

.auth-modal-checklist li {
  margin-bottom: 10px;
  font-size: 1em;
  color: #ccc;
  text-align: left; /* Align text to the left for readability */
}
.auth-modal-checklist li::before {
  content: '✓ '; /* Checkmark symbol */
  color: #ff7f00; /* Orange checkmark */
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}


/* Auth Modal Buttons Container */
.auth-modal-buttons {
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Space between buttons */
}

/* Auth Modal Button Style (Shared) */
.auth-modal-button,
.report-modal-submit-button,
.pro-plan-card,
.modal-close-button,
.game-modal-close-button,
.upload-game-button,
.earn-button {
  background-color: #ff7f00; /* Orange background */
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.2s ease;
  text-decoration: none; /* Remove underline from pro plan cards if they become links */
  display: inline-block; /* Ensure proper spacing for pro plan cards */
  text-align: center;
}

.auth-modal-button:hover,
.report-modal-submit-button:hover,
.pro-plan-card:hover,
.modal-close-button:hover,
.game-modal-close-button:hover,
.upload-game-button:hover,
.earn-button:hover {
  background-color: #e67300; /* Darker orange on hover */
}

/* Specific button styles if needed (e.g., for Login/Signup differentiation) */
.auth-modal-login-button {
  /* Add specific styles for login button if needed */
}

.auth-modal-signup-button {
  /* Add specific styles for signup button if needed */
}

/* Mobile Adjustments for Auth Modal */
@media (max-width: 768px) {
  .auth-modal-content,
  .report-modal-content,
  .video-modal-content,
  .pro-modal-content,
  .compute-modal-content {
      padding: 20px;
      max-width: 95%;
  }
  .auth-modal-title,
  .report-modal-title,
  .video-modal-title,
  .pro-modal-title,
  .compute-modal-title {
      font-size: 1.5em;
      margin-bottom: 15px;
  }
  .auth-modal-checklist li {
      font-size: 0.9em;
      margin-bottom: 8px;
  }
  .auth-modal-buttons,
  .pro-modal-plans {
      gap: 15px;
      flex-direction: column; /* Stack buttons on mobile */
      align-items: stretch; /* Make buttons full width */
  }
  .auth-modal-button,
  .report-modal-submit-button,
  .pro-plan-card,
  .upload-game-button,
  .earn-button {
      padding: 12px 20px;
      font-size: 1em;
      width: 100%; /* Full width buttons on mobile */
      box-sizing: border-box; /* Include padding in width */
  }
}

.auth-modal-close-button,
.report-modal-close-button,
.video-modal-close-button,
.pro-modal-close-button,
.modal-close-button,
.game-modal-close-button{
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    color: red; /* Light grey color */
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.2s ease;
    padding: 3px; /* Add some padding for better touch area */
}

.auth-modal-close-button:hover,
.report-modal-close-button:hover,
.video-modal-close-button:hover,
.pro-modal-close-button:hover,
.modal-close-button:hover,
.game-modal-close-button:hover {
    color: white; /* White on hover */
}

/* Report Modal Specific Styles */
.report-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.report-option {
    margin-bottom: 10px;
    color: #ccc;
    display: flex;
    align-items: center;
}

.report-option input[type="radio"] {
    margin-right: 10px;
}

.report-modal-actions {
    display: flex;
    justify-content: flex-end; /* Align button to the right */
}

.report-modal-submit-button:disabled {
    background-color: #777; /* Greyed out when disabled */
    cursor: not-allowed;
}

/* Video Modal Specific Styles */
.video-container {
    width: 100%;
    max-height: 80vh; /* Limit video height to viewport */
    overflow: hidden; /* Ensure video stays within container */
}

.modal-feed-video {
    object-fit: contain; /* Ensure video scales within container */
    max-height: 70vh; /* Limit video height within modal */
    width: auto;     /* Adjust width automatically based on aspect ratio */
    max-width: 100%; /* Ensure video doesn't exceed modal width */
    display: block;  /* Ensure proper layout */
    margin: 0 auto;  /* Center video horizontally */
}

/* Pro Modal Specific Styles */
.pro-modal-container {
    display: flex;
    flex-direction: row; /* Plans on left, features on right */
    gap: 30px; /* Spacing between plans and features */
    align-items: flex-start; /* Align items to the start of the container */
}

.pro-modal-plans-area {
    flex: 1; /* Take up available space */
    max-width: 300px; /* Limit width of plans area if needed */
}
.pro-modal-features-area {
  flex: 2;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;      /* Vertically center if needed */
  flex-direction: column;
  text-align: left;
}

.pro-modal-features {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between each feature item */
  font-size: 0.9em;
  color: #ccc;
}


.pro-modal-features li::before {
    content: '• '; /* Simpler bullet point */
    color: #ff7f00;
    margin-right: 8px; /* Reduced margin */
    font-size: 1em; /* Adjust icon size if needed */
    display: inline-block;
    width: auto; /* Adjust width as needed */
    text-align: center;
}


.pro-modal-plans {
    display: flex;
    flex-direction: column; /* Stack plans vertically */
    gap: 15px;
    margin-bottom: 20px;
}

.pro-plan-card {
    background-color: #2a2a2a;
    padding: 15px; /* Reduced padding */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width within plans area */
    box-sizing: border-box;
    color: white;
}

.pro-plan-card h3 {
    margin-top: 0;
    margin-bottom: 8px; /* Reduced margin */
    font-size: 1.2em; /* Reduced font size */
    color: #ff7f00;
}

.pro-plan-description {
    font-size: 0.85em; /* Reduced font size */
    color: #ccc;
    margin-bottom: 12px; /* Reduced margin */
    text-align: center;
}

.pro-plan-price {
    font-size: 1.3em; /* Reduced font size */
    font-weight: bold;
}

/* Mobile adjustments for Pro Modal Layout */
@media (max-width: 768px) {
    .pro-modal-container {
        flex-direction: column; /* Stack plans and features on mobile */
        gap: 20px;
    }
    .pro-modal-plans-area, .pro-modal-features-area {
        width: 100%; /* Full width on mobile */
        max-width: none;
    }
    .pro-modal-plans {
        flex-direction: row; /* Plans in a row on mobile */
        overflow-x: auto; /* Allow horizontal scrolling for plans */
        width: 100%;
        padding-bottom: 10px; /* Space for scrollbar */
    }
    .pro-plan-card {
        width: 200px; /* Fixed width for cards in horizontal scroll */
        flex-shrink: 0; /* Prevent cards from shrinking */
        padding: 15px;
    }
    .pro-modal-features li {
        font-size: 0.9em; /* Keep font size consistent on mobile */
    }
}


/* --- New Style for embed_playr iframe --- */
.feed-iframe-embed-playr {
  /* Keep the same inset values to maintain the capture area */
  clip-path: inset(35% 30% 10% 6%); /* top right bottom left */

  /* Center the content after cropping */
  transform: scale(1.5) translateX(15%) translateY(-10%);
  transform-origin: center center;
  overflow: hidden;
  position: relative;
}